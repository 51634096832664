AFRAME.registerComponent('server-digits-animation', {
    schema: {
      serverCooling01: { default: 1.0 }
    },
    init: function () {
      // Create and animate digits with a delay
      this.createDigitParticles(this.el);
    },
    createDigitParticles: function (serverEntity) {
      // Define digit particle properties
      const digits = ['0', '1', '0110', '11001', '0101011'];
      const particleCount = 7;
      const dissolveDuration = 2000; // Duration in milliseconds
      const delayBetweenParticles = 750; // Delay between each particle in milliseconds
  
      digits.forEach(digit => {
        for (let i = 0; i < particleCount; i++) {
          setTimeout(() => {
            // Randomize position around the server entity
            const posX = Math.random() * 0.3 - 0.15;
            const posY = Math.random() * 0.3 + 1.65;
            const posZ = Math.random() * 0.3 + 1.15;
  
            // Create digit particle entity
            const digitEntity = document.createElement('a-text');
            digitEntity.setAttribute('value', digit);
            digitEntity.setAttribute('position', `${posX} ${posY} ${posZ}`);
            digitEntity.setAttribute('color', '#00FF00');
            digitEntity.setAttribute('scale', '0.5 0.5 0.5');
            digitEntity.setAttribute('class', 'serverDigit');
            if (this.data.serverCooling01 < 0.1) {
              digitEntity.setAttribute("visible", false);
            }
  
            // Append to the scene
            serverEntity.appendChild(digitEntity);
  
            // Animate the digit particle
            this.animateDigitParticle(digitEntity, dissolveDuration);
          }, i * delayBetweenParticles); // Apply delay between particles
        }
      });
    },
    animateDigitParticle: function (digitEntity, duration) {
      // Animate the digit particle to dissolve after 'duration' milliseconds
      digitEntity.setAttribute('animation', {
        property: 'position',
        dur: duration,
        easing: 'linear',
        loop: true,
        to: `${digitEntity.getAttribute('position').x} ${digitEntity.getAttribute('position').y} ${digitEntity.getAttribute('position').z + 1}` // Move to front
      });
  
    },
    tick: function (time) {
      const serverCooling01 = this.data.serverCooling01;
      //console.log("servercooling01", serverCooling01);
      const children = this.el.children;
      /* TODO: this looks a bit stupid, because it flickers quite a bit
      if (serverCooling01 < 0.1) {
        for (let i = 0, len = children.length; i < len; ++i) {
          children[i].setAttribute("visible", false);
        }
      } else {
        for (let i = 0, len = children.length; i < len; ++i) {
          children[i].setAttribute("visible", true);
        }
      }
      */
    }
  });